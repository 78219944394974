/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

.pointer {
  cursor: pointer;
}

.login-main {
  width: 100%;
  height: 100vh;
}

.login-image {
  background-color: #a0c6ca;
  padding: 55px 0px 55px 55px;
}

.banner {
  width: 100%;
  height: 100%;
}

.login-content {
  padding: 55px 55px 55px 0px;
}

.content-main {
  background-color: #a0c6ca;
  height: 100%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

@media only screen and (max-width: 767px) {
  .login-content {
    padding: 25px 25px 25px 25px;
  }

  .content-main {
    background-color: #a0c6ca;
    border-radius: 30px;
  }
}

#email {
  border-color: white;
  box-shadow: none;
}

#envelope-border {
  border-right: 2px solid #a0c6ca;
}

#password {
  border-color: white;
  box-shadow: none;
}

#ConfirmPassword {
  border-color: white;
  box-shadow: none;
}

#vectorBorder {
  border-right: 2px solid #a0c6ca;
}

.login-btn {
  background-color: #336ba6;
  color: white;
}

.login-btn:hover {
  color: white;
}

.largerCheckbox {
  width: 20px;
  height: 20px;
}

.forget-password {
  color: #336ba6;
}

.EyeIcon {
  cursor: pointer;
}

.otp-expire {
  color: #336ba6;
}

.resend-otp {
  color: #336ba6;
}

.opt-parent > div {
  justify-content: center !important;
}

.otp-input {
  font-size: 38px !important;
  border: none;
  box-shadow: none;
  margin: 0px 10px;
  width: 51px;
  height: 47px;
  border-radius: 5px;
}

@media only screen and (max-width: 430px) {
  .otp-input {
    margin: 0px 1px;
  }
}

/* --------------dashboard------------------- */

.line-through-2 {
  text-decoration-thickness: 2px !important;
}

/* .driving {
  background-color: #f04d4d !important;
}

.canceled {
  background-color: #cca88e !important;
}

.off {
  background-color: #5cbe77 !important;
}

.lunch {
  background-color: #4a4a4a !important;
}

.new {
  background-color: #fdd61b !important;
} */

.navbar2 {
  background-color: #9cc1c5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* orde */
}

.search-parent {
  max-width: 600px;
  /* border: 2px solid red; */
}

.export {
  background-color: #336ba6;
  font-weight: 600;
  font-size: 14px;
  padding: 12px;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.avator {
  width: 50px;
  height: 50px;
}

.logout-widget {
  width: 180px;
  background-color: white;
  right: 0px;
  top: 60px;
  border: none;
  border-radius: none !important;
}

.input-boxshadow-none {
  box-shadow: none !important;
}

.logout-widget > ul > li {
  color: #909090;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logout-widget > ul > li:hover {
  background-color: #9cc1c5 !important;
  color: White;
}

.logout-widget-item {
  color: #909090;
}

.logout-widget-item:hover {
  background-color: #9cc1c5 !important;
  color: White;
}

.bs-parent {
  background-color: #e4f0f1;
}

.bs {
  letter-spacing: 5px;
  margin-left: 70px;
}

.bs-drop-down {
  position: absolute;
  left: -4% !important;
  top: 25px;
  letter-spacing: 2px;
  font-size: 14px;
  width: 140px;
  text-align: center;
  color: white;
  z-index: 11;
}

.bs-drop-down > li {
  border-bottom: 1px solid #dee2e6;
  padding: 8px 8px 8px 8px;
  font-weight: 500;
}

.table_head {
  width: 350px !important;
}

.date-calender {
  width: 100% !important;
  height: 100% !important;
}

.date-calender > div > div > div > div > div {
  justify-content: space-between !important;
}

.date-calender > div > div > div {
  justify-content: space-between !important;
}

.css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  border: none !important;
}

.css-1aqny2q-MuiPickersCalendarHeader-root {
  background-color: #e4f0f1;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.meeting-person-placeholder::placeholder {
  color: white !important;
}

.customYearPicker {
  vertical-align: middle !important;
  padding: 0px !important;
  height: 100% !important;
}

.customYearPicker > div > div > button {
  padding: 0px;
  margin: 0px;
}

.customYearPicker > div {
  vertical-align: middle !important;
  padding: 0px;
}

.customYearPicker > div > input {
  padding: 0px;
  text-align: center;
  max-width: 40px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.customYearPicker > div > fieldset {
  border: none !important;
}

.customMonthPicker {
  vertical-align: middle !important;
  padding: 0px !important;
  height: 100% !important;
}

.customMonthPicker > div > div > button {
  padding: 0px;
  margin: 0px;
}

.customMonthPicker > div {
  vertical-align: middle !important;
  padding: 0px;
}

.customMonthPicker > div > input {
  padding: 0px;
  text-align: center;
  max-width: 116px !important;
  font-weight: 700 !important;
  font-size: 15px !important;
}

.customMonthPicker > div > fieldset {
  border: none !important;
}

.customDayPicker {
  vertical-align: middle !important;
  padding: 0px !important;
  height: 100% !important;
}

.customDayPicker > div > div > button {
  padding: 0px;
  margin: 0px;
}

.customDayPicker > div {
  vertical-align: middle !important;
  padding: 0px;
}

.customDayPicker > div > input {
  padding: 0px;
  text-align: center;
  max-width: 140px !important;
  /* width: 220px !important; */
  font-weight: 700 !important;
  font-size: 15px !important;
}

.customDayPicker > div > fieldset {
  border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.plus-icon {
  right: 0px;
}

.edit-able-inputs {
  background-color: transparent;
  width: 100% !important;
  border: 0px solid;
  border-bottom: 1px solid black;
  outline: none;
  font-weight: 600;
}

.edit-able-inputs-name {
  background-color: transparent;
  width: 100% !important;
  min-width: 60px;
  border: 0px solid;
  border-bottom: 1px solid black;
  outline: none;
  color: white;
  font-weight: 600;
}

.arrows {
  margin-top: -100px !important;
}

.notification {
  height: 340px;
  overflow-y: scroll;
}

/* ------------------------registered-users--------------------------- */
.add-user-button {
  background-color: #336ba6;
  border-radius: 5px;
  border: none;
  color: white;
  font-weight: 600;
  padding: 10px 15px;
}

.dot-active {
  width: 12px;
  /* Size of the dot */
  height: 12px;
  border-radius: 50%;
  /* Makes the div round */
  display: inline-block;
  margin-right: 5px;
  /* Spacing between dots */
  background-color: #5cbe77;
}

.dot-in-active {
  width: 12px;
  /* Size of the dot */
  height: 12px;
  border-radius: 50%;
  /* Makes the div round */
  display: inline-block;
  margin-right: 5px;
  /* Spacing between dots */
  background-color: #cca88e;
}

.active {
  background-color: #c9e2fc;
}

.in-active {
  background-color: #e4dfd5;
}

.break-word {
  word-break: break-all;
  overflow-wrap: break-word;
}

.fw-semibold {
  font-weight: 600;
}

.three-dot-menu {
  min-width: 85px !important;
  /* background-color: red; */
  font-size: 14px;
  position: absolute;
  text-align: center;
  left: -20px !important;
  padding: 0px;
  border: none;
}

.three-dot-menu > li:hover {
  background-color: #9cc1c5;
  color: white;
}

.three-dot-menu-status {
  min-width: 85px !important;
  /* background-color: red; */
  font-size: 14px;
  position: absolute;
  text-align: center;
  left: -10px !important;
  padding: 0px;
  border: 1px solid gray;
}

.three-dot-menu-status > li:hover {
  background-color: #9cc1c5;
  color: white;
}

/* --------------------my-profile------------------------------ */
.react-international-phone-input {
  width: 100%;
}

.table > :not(caption) > * > * {
  border: none !important;
  padding: 0px;
   /* padding-top: 1px; */
  /* padding-bottom: 1px;  */
}

.table {
  border-width: 2px !important;
  border-color: gray !important;
  border-style: solid !important;
}

.table thead tr .custom-border {
  border-width: 2px !important;
  border-color: gray !important;
  border-style: solid !important;
  /* min-width: 600px !important;  */
}

.table .custom-border {
  border-width: 2px !important;
  border-color: gray !important;
  border-style: solid !important;
  min-width: 200px !important;
  max-width: 200px !important;
  text-wrap: wrap !important; /* Doubled the minWidth 100 to 200 */
  /* padding-top: 25px;
  padding-bottom: 25px; */
}

.seperator {
  min-width: 20px !important;
}
.table .specific-column {
  min-width: 80px !important;
  max-width: 90px !important;
  white-space: nowrap !important;
}
.truncated {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Optional: set a fixed max-width or width to the element if needed */
  max-width: 250px; 
}

/* Styles for the menu button */
.menu-button {
  display: none;
  position: absolute;
  /* background-color: #ff9090; */
  width: 22px;
  height: 22px;
  text-align: center;
  right: 5px;
  top: -2px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10;
}

.menu-container {
  position: relative;
}

.menu-container:hover .menu-button {
  display: block;
}

/* Styles for the dropdown menu */
.menu {
  display: none;
  position: absolute;
  top: 30px;
  right: 5px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 20;
}

.menu-container:hover .menu {
  display: block;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

/* TO make bottom scroll sticky (INCOMPLETE) */

.sticky-scrollbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* top:100px; */
  z-index: 1000;
  background: white;
}

.days-wrapper {
  margin-left: 9px;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  height: calc(100vh - 154px);
}
/* Bottom Scroll */
.days-wrapper::-webkit-scrollbar {
  height: 15px;
}

.days-wrapper::-webkit-scrollbar-thumb {
  background-color: #888;
}

.days-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



/* Meeting Note */

.custom-modal .modal-dialog {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 0 auto; /* Center horizontally */
  height: 100vh; /* Full viewport height */
}

.custom-tooltip {
  white-space: pre-wrap; 
  overflow-wrap: break-word;
  position: absolute;
  top: -24px;
  left: 200px;
  background-color: #f9ffa5;
  color: #000; /* Tooltip text color */
  padding: 0px 3px 0px 3px;
  /* border-radius: 4px; Rounded corners */
  font-size: 13px;
  white-space: normal;
  z-index: 10;
  opacity: 0.9;
  pointer-events: auto; /* Allow tooltip to be clickable */
  border: 1px solid black;
  max-width: 200px;
  width: max-content;
  box-sizing: border-box;
}

.tooltip-edit-input {
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 4px;
  background: #fff;
  color: #000;
  outline: none;
}

.table-responsive {
  position: relative;
}

.days-wrapper {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
}

.scroll-buttons {
  position: absolute;
  bottom: 0;
  right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #ffffff;
  z-index: 1;
  line-height: 0%;
  /* gap: 5px; */
}

.scroll-button {
  background-color: #ffffff;
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
  /* padding: 5px; */
  /* font-size: 12px; */
}

.scroll-button.left {
  margin-right: 5px;
}

.scroll-button.right {
  margin-left: 5px;
}

.scroll-buttons2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 15px;
  right: 1px;
  background-color: #ffffff;
  z-index: 1;
  line-height: normal;
  /* gap: 5px; */
}

.scroll-button2 {
  background-color: #ffffff;
  border: none;
  /* border-radius: 4px; */
  cursor: pointer;
  /* padding: 5px; */
  /* font-size: 12px; */
}

.scroll-button2.top {
  margin-top: 5px;
}

.scroll-button2.bottom {
  margin-bottom: 5px;
}

.table{
  width: 200px !important
}